<template>
  <Screen is-sub></Screen>
</template>

<script>
import Screen from './index.vue';
export default {
  //组件注册
  components: { Screen },
  //组件传值
  props: {},
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="less" scoped></style>
