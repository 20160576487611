<template>
  <div
    class="screen-container"
    :style="{
      'background-image': `url(${require(`../../assets/screen/bj/${
        ['earlyWarning'].includes(pageKey) ? pageKey : 'default'
      }.jpg`)})`,
    }"
  >
    <ScreenHeader></ScreenHeader>
    <ScreenNeck :page-key="pageKey"></ScreenNeck>
    <ScreenContent :page-key="pageKey"></ScreenContent>
    <ScreenTabs v-model="pageKey" />
    <CameraDialog ref="CameraDialog"></CameraDialog>
    <ImgDialog ref="ImgDialog"></ImgDialog>
  </div>
</template>

<script>
import { pageTabs } from './config';
import { mapMutations } from 'vuex';
import CameraDialog from './components/CameraDialog.vue';
import ImgDialog from './components/ImgDialog.vue';
export default {
  name: 'Screen',
  //组件注册
  components: {
    ScreenHeader: () => import('./modules/Header.vue'),
    ScreenNeck: () => import('./modules/Neck.vue'),
    ScreenContent: () => import('./modules/Content.vue'),
    ScreenTabs: () => import('./modules/Tabs.vue'),
    CameraDialog,
    ImgDialog,
  },
  provide() {
    return {
      pageTabs,
    };
  },
  //组件传值
  props: {
    isSub: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {
    pageKey: {
      get() {
        return this.$store.state.screenStore.pageKey;
      },
      set(val) {
        this['screenStore/SET_pageKey'](val);
      },
    },
  },
  //属性监听
  watch: {},
  created() {
    this['screenStore/SET_isSubbranch'](this.isSub);
  },
  //DOM访问
  mounted() {
    this.$bus.$on('CameraDialog', (options) => {
      this.$refs.CameraDialog && this.$refs.CameraDialog.openDialog(options);
    });
    this.$bus.$on('ImgDialog', (options) => {
      this.$refs.ImgDialog && this.$refs.ImgDialog.openDialog(options);
    });
  },
  //保存方法
  methods: {
    ...mapMutations(['screenStore/SET_pageKey', 'screenStore/SET_isSubbranch']),
  },
};
</script>

<style lang="scss" scoped>
.screen-container {
  width: 100%;
  height: 100vh;
  color: #e9faff;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  overflow: hidden;
  position: absolute;
}
@media screen and (max-height: 1000px) {
  ::v-deep .img_center {
    background-size: 100% 100%;
  }
}
</style>
